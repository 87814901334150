<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="邮政活动ID"
                    prop="act_id"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入邮政活动ID"
                  v-model="form.act_id"></el-input>
      </el-form-item>
      <el-form-item label="活动区域"
                    prop="list_province">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="广州邮政会员验证"
                    prop="is_verify_micro_mall">
        <el-radio-group v-model="form.is_verify_micro_mall">
          <el-radio :label="true">启用</el-radio>
          <el-radio :label="false">不启用</el-radio>

        </el-radio-group>
      </el-form-item>
      <el-form-item label="预约成功提示语"
                    prop="appointment_desc"
                    class="width300">
        <el-input type="textarea"
                  placeholder="请输入预约成功提示语"
                  v-model="form.appointment_desc"></el-input>
      </el-form-item>
      <el-form-item label="封面图片"
                    prop="img_id">
        <ImgUpload :fileList="imgList"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="背景图片"
                    prop="banner_img_id">
        <ImgUpload :fileList="imgList2"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.banner_img_id" />
      </el-form-item>
      <el-form-item label="二维码背景图"
                    prop="qrcode_bg_img_id">
        <ImgUpload :fileList="imgList3"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.qrcode_bg_img_id" />
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <Editor v-model="form.rule" />
      </el-form-item>
      <el-form-item label="预约起止时间"
                    prop="appointment_started_at">
        <el-date-picker v-model="dateTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="dateChange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="领取起止时间"
                    prop="snap_up_started_at">
        <el-date-picker v-model="dateTime2"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="date2Change"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="是否必须关联员工码"
                    required>
        <el-radio-group v-model="form.is_marketer_code">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>

        </el-radio-group>
      </el-form-item>
      <el-form-item label="总金额"
                    prop="total_money"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入总金额"
                  v-model.number="form.total_money"></el-input>
      </el-form-item>

      <el-form-item label="初始参与数"
                    prop="init_num"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入初始参与数"
                  v-model.number="form.init_num"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setActivity, getActivity } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import Editor from '@/components/Editor'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  data () {
    return {
      imgList: [],
      imgList2: [],
      imgList3: [],
      dateTime: [],
      dateTime2: [],
      address: {},
      areaAddress: {},
      form: {
        type: 'DRAWAL', // 活动类型
        act_id: '', // 邮政ID
        qrcode_bg_img_id: '', // 二维码背景图
        img_id: '', // 封面图片
        banner_img_id: '', // 背景图片
        rule: '', // 规则
        list_province: '', // 活动区域（省份）
        list_city: '', // 活动区域（城市）
        list_district: '', // 活动区域（区）
        list_net: '', // 活动区域（网点）
        is_verify_micro_mall: false, // 是否开启会员验证
        appointment_started_at: '', // 预约开始时间
        appointment_ended_at: '', // 预约结束时间
        snap_up_started_at: '', // 领取开始时间
        snap_up_ended_at: '', // 领取结束时间
        is_marketer_code: false, // 是否必须员工码
        total_money: 0, // 总金额单位分
        init_num: 0, // 初始参与人数
        appointment_desc: '' // 预约成功提示语
      },
      rules: {
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'change' }
        ],
        act_id: [
          { required: true, message: '请输入邮政活动ID', trigger: 'blur' }
        ],
        is_verify_micro_mall: [
          { required: true, message: '请选择广州邮政会员验证', trigger: 'change' }
        ],
        is_marketer_code: [
          { required: true, message: '请选择是否必须关联员工码', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        appointment_desc: [
          { required: true, message: '请输入预约提示语', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请输入上传封面图片', trigger: 'change' }
        ],
        qrcode_bg_img_id: [
          { required: true, message: '请输入二维码背景图', trigger: 'change' }
        ],
        banner_img_id: [
          { required: true, message: '请输入上传背景图', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '选择活动日期', trigger: 'change' }
        ],
        list_province: [
          { required: true, message: '请选择区域，至少为省份', trigger: 'change' }
        ],
        appointment_type: [
          { required: true, message: '请选择活动类型', trigger: 'change' }
        ],
        mark_topic_num: [
          { required: true, message: '请输入每日参与的总次数', trigger: 'blur' }
        ],
        mark_topic_msg: [
          { required: true, message: '请输入达标提示语', trigger: 'blur' }
        ],
        total_money: [
          { required: true, message: '请输入总金额', trigger: 'blur' }
        ],
        init_num: [
          { required: true, message: '请输入初始参与数', trigger: 'blur' }
        ],
        appointment_started_at: [
          { required: true, message: '请选择预约起止时间', trigger: 'change' }
        ],
        snap_up_started_at: [
          { required: true, message: '请选择领取起止时间', trigger: 'change' }
        ]

      },
      money: [
        {
          money: 1,
          num: 10
        }
      ]
    }
  },
  computed: {
    totalMoney () {
      let total = 0
      this.money.map(item => {
        total += item.money * 100 * item.num
      })
      return (total / 100).toFixed(2)
    }
  },
  components: {
    Area,
    ImgUpload,
    Editor
  },
  methods: {
    delMoney (index) {
      if (this.money.length > 1) {
        this.money.splice(index, 1)
      } else {
        this.$message.error('显示字段最少为一项')
      }
    },
    addMoney () {
      if (this.money.length < 8) {
        this.money.push({
          money: 1,
          num: 10
        })
      } else {
        this.$message.error('最多支持8个奖品')
      }
    },
    dateChange (e) {
      if (e) {
        this.form.appointment_started_at = e[0]
        this.form.appointment_ended_at = e[1]
      }
    },
    date2Change (e) {
      if (e) {
        this.form.snap_up_started_at = e[0]
        this.form.snap_up_ended_at = e[1]
      }
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const fromData = JSON.parse(JSON.stringify(this.form))
          fromData.total_money = fromData.total_money * 100
          const res = await setActivity(fromData)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('extract')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity
        activity.activity_id = id
        activity.total_money = activity.total_money / 100
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList, 0, activity.img_data)
        activity.banner_img_id = getImgId(activity.banner_img_data)
        activity.banner_img_data && this.$set(this.imgList2, 0, activity.banner_img_data)
        activity.qrcode_bg_img_id = getImgId(activity.qrcode_bg_img_data)
        activity.qrcode_bg_img_data && this.$set(this.imgList3, 0, activity.qrcode_bg_img_data)
        this.dateTime = [activity.appointment_started_at, activity.appointment_ended_at]
        this.dateTime2 = [activity.snap_up_started_at, activity.snap_up_ended_at]
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.form = activity
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
